<template>
  <div class="container is-widescreen">
    <div class="columns is-centered">
      <div class="column is-8">
        <div class="activity-navbar">
          <b-button
            class="left"
            :loading="loadingReject"
            @click="confirmReject()"
          >
            No Acepto
          </b-button>
          <b-button
            class="right"
            :class="{ disabled: !scrolledToBottom }"
            :loading="loadingAccept"
            @click="updateTerms(true)"
          >
            Acepto
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedConstants from '@/shared/constants.json'
import { mapGetters, mapState } from 'vuex'
import { parseURLTncType } from './tncURLs'

export default {
  name: 'TermsAndConditionsDetailsNavbar',
  data() {
    return {
      termsType: parseURLTncType(this.$route.params.type),
      loadingAccept: false,
      loadingReject: false,
      projectIdentifier: this.$route.params.identifier,
      scrolledToBottom: false
    }
  },
  computed: {
    ...mapGetters('participationProject', ['isAnonymousProject']),
    ...mapState('participationProject', ['projectData'])
  },
  mounted() {
    this.atWindowBottom()
  },
  methods: {
    atWindowBottom() {
      window.onscroll = () => {
        const bottomOfWindow = (Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) + window.innerHeight) >= document.documentElement.offsetHeight - 50

        if (bottomOfWindow) {
          this.scrolledToBottom = true // replace it with your code
        }
      }
    },
    confirmReject() {
      this.$buefy.dialog.confirm({
        title: 'Términos y Condiciones',
        message:
          "<div class='text'>¿Estás seguro de que no aceptás estos Términos y Condiciones?<br>No podrás volver "
          + 'a acceder ni participar del proyecto.<br>Esta acción no se puede deshacer.</div>',
        confirmText: 'Sí, estoy seguro',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        ariaRole: 'alertdialog',
        onConfirm: () => this.updateTerms(false)
      })
    },
    async updateTerms(action) {
      if (action === true) {
        this.loadingAccept = true
      } else {
        this.loadingReject = true
      }
      const success = await this.$store.dispatch('participationParticipant/updateTncStatus', {
        projectIdentifier: this.projectIdentifier,
        tncAction: { accepted: action, terms: this.termsType }
      })
      if (action === true) {
        this.loadingAccept = false
      } else {
        this.loadingReject = false
      }

      if (success) {
        this.$store.dispatch('alerts/triggerSuccessAlert', {
          message: (
            'Términos y Condiciones '
            + `${this.termsType === SharedConstants['TNC_TYPE']['RELEVANT'] ? 'de Relevant' : 'del investigador'} `
            + `${action ? 'aceptados' : 'rechazados'}.`)
        })

        if (action) {
          this.$router.push({
            name: 'participation-terms-and-conditions',
            params: { id: this.projectIdentifier }
          })
        } else if (this.isAnonymousProject) {
          // TODO: qué mostrar al rechazar proyecto anónimo?
          console.log('Anonymous!')
        } else {
          this.$router.push({
            name: 'proyectos'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-navbar {
  height: 60px;
  background: $white;
  border: 1px solid $gray-ultralight;
  box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.hidden {
    display: none;
  }

  button {
    width: 130px;
    height: 35px;
    border-radius: 17.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    &.left {
      background: $white;
      border: 1px solid $button-border-gray;
      color: $black;
      margin-left: 14px;

      &:hover {
        border: 1px solid $gray;
        transition: all 0.2s ease-in-out;
      }
    }

    &.right {
      color: $white;
      background: $blue;
      box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
      border: none;
      margin-right: 14px;

      &:hover {
        background-color: $blue-hover;
        transition: all 0.2s ease-in-out;
      }
    }

    &.disabled {
      pointer-events: none;
      background-color: $button-disabled;
    }
  }
}

.dialog {
  .modal-card {
    max-width: 576px;
  }
}
</style>

<style lang="scss">
.dialog[role="alertdialog"] {
  .modal-card {
    max-width: 576px;
    width: 576px;
    padding: 24px 24px;

    .modal-card-head {
      background: url("../../../assets/terms/term-alert-icon.svg") center top
        no-repeat;
      font-family: $font-family;
      padding: 71px 0px 0px;
      margin-bottom: 20px;
      text-align: center;
    }

    .modal-card-body {
      padding: 0px 0px;
      border: none;
      text-align: center;

      .media {
        width: 100%;

        .media-left {
          display: none;
        }

        .media-content {
          font-family: $font-family-secondary;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .modal-card-foot {
      padding: 0px 0px;
      margin-top: 20px;
      justify-content: center;

      button {
        &:first-child {
          background-color: $white;
          color: $blue;
          border: 1px solid $blue;

          &:hover {
            background-color: $gray-ultralight;
          }
        }

        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
