<template>
  <participation-view-content-container :current-stage="currentStage">
    <terms-and-conditions-details />
    <terms-and-conditions-details-navbar class="float-bottom-navbar" />
  </participation-view-content-container>
</template>

<script>
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import TermsAndConditionsDetails from '@/components/participation/terms-and-conditions/TermsAndConditionsDetails.vue'
import TermsAndConditionsDetailsNavbar from '@/components/participation/terms-and-conditions/TermsAndConditionsDetailsNavbar.vue'
import participationStages from '@/components/participation/participationStages'

export default {
  name: 'TerminosDetalle',
  components: {
    ParticipationViewContentContainer,
    TermsAndConditionsDetails,
    TermsAndConditionsDetailsNavbar
  },
  created() {
    this.currentStage = participationStages.termsAndConditions
  }
}
</script>
