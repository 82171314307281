import SharedConstants from '@/shared/constants.json'

const parseURLTncType = (URLType) => {
  if (URLType === 'relevant') {
    return SharedConstants['TNC_TYPE']['RELEVANT']
  }

  if (URLType === 'investigador') {
    return SharedConstants['TNC_TYPE']['PROJECT']
  }

  return null
}

export { parseURLTncType }
